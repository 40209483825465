import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
    

  
        <div class="container-fluid footer pt-5 px-4">
            
               
                <div class="row g-5">
                    <div class="col-lg-6 col-xl-5">
                        <div class="footer-item-1">
                            <div class="row mb-4">
                                
                                
                                <div class="">
                                    <div class="ftr-nav">
                                        <Link class="" to="/about/">About </Link>
                                        <Link class="" to="/contact/">Contact </Link>
                                       
                                    </div>


                                    <div class="ftr-nav">
                                       
                                        <Link class="" to="/privacy-policy/">Privacy Policy</Link>
                                        <Link class="" to="/blogs/">Blog</Link>
                                    </div>
                                </div>
                            
                        </div>
                           
                        </div>
                    </div>
                    <div class="col-lg-6 col-xl-7">
                        <div class="footer-item-2">
                            <div class="d-flex flex-column mb-4">
                                
                                
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex line-h">
                                            <a class="social-icon btn-md-square" href=""><i class="fab fa-twitter "></i></a>
                                            <a class="social-icon btn-md-square" href=""><i class="fab fa-facebook-f "></i></a>
                                            <a class="social-icon btn-md-square" href=""><i class="fab fa-instagram "></i></a>
                                            <a class="social-icon btn-md-square" href=""><i class="fab fa-pinterest "></i></a>
                                        </div>
                                    </div>
                                
                            </div>
                          
                        </div>
                    </div>
                   
                   
                </div>
                <div class=" copyright py-4">
                    
                        <div class="row">
                           
                            <div class="col-md-6 my-auto text-center text-md-start text-white">
                              <Link to="/">
                               <img src="/assets/img/logo.png" class="img-responsive" alt="ftr logo" />
                               </Link>
                            </div>
                            <div class="col-md-6 text-center text-md-end mb-3 mb-md-0">
                                <span class=""><a href="#"><i class="fas fa-copyright  me-2"></i>Contact-customerservice</a> All rights reserved.</span>
                            </div>
                        </div>
                    
                </div>
            
        </div>
       
     
        


        
        <a href="#" class="btn btn-primary border-2 border-white rounded-circle back-to-top"><i class="fa fa-arrow-up"></i></a>   

        
        
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
        <script src="lib/easing/easing.min.js"></script>
        <script src="/assets/lib/waypoints/waypoints.min.js"></script>
        <script src="/assets/lib/owlcarousel/owl.carousel.min.js"></script>

        
        <script src="/assets/js/main.js"></script>
  


    </>
  )
}

export default Footer