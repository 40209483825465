import React from 'react';
import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";




function Blog() {
  const { data } = useParams();
  const id = 19;
  const [individualPost, setIndividualPost] = useState([]);
  console.log("data", individualPost?.title?.rendered)
  const [param, setParam] = useState(data);
  useEffect(() => {
 
      axios
        .get(`https://www.contact-customerservice.co/apisslug.php?url=${data}`, {})
        .then((res) => {
          setIndividualPost(res?.data);
        });
    
  }, [param]);
  
  return (
    <>
     <Helmet>
        <title>{individualPost[0]?.seotitle}</title>
        <meta name='description' content={individualPost[0]?.seodescription} />
      </Helmet>
     <div class="container-fluid bg-dark ">
            <div class="container  text-left">
                <ol class="breadcrumb justify-content-start py-2">
                    <li class="breadcrumb-item"><a href="/" class="text-light">Home</a></li>
                    <li class="breadcrumb-item"><a href="#" class="text-light">Details</a></li>
              
                </ol>
                
            </div>
        </div>

      
        <div class="container-fluid post-detail py-4 px-4">
            <div class="">
              
                <div class="row g-4">

                 <div class="col-md-10 mx-auto">
                 {individualPost && individualPost[0]?.title && (
              <h1>{individualPost[0]?.title}</h1>
              )} 

                       <div class="d-flex auth-info ">
                        <p class="mb-0 fw-bold pe-2 text-dark">Willium Smith</p>
                        <p class="mb-0 "> {individualPost[0]?.posted_date}</p>
                    </div>
                 </div>       




                    <div class="col-lg-10 mx-auto">
                      
                        <div class="position-relative overflow-hidden mb-3">
                            <img src={individualPost[0]?.photo} class="img-zoomin img-fluid w-100" alt="" />
                            <div class="position-absolute text-white px-2 small bg-dark" >                                              
                            Mary
                            </div>
                        </div>
                      
                        <div class="post-content">
    
                        <div
                        dangerouslySetInnerHTML={{
                          __html: individualPost[0]?.description,
                        }}
                      />
                        </div>
                        
                       
                        <div class="bg-light rounded p-4">
                            <h4 class="mb-4">Contact Customer Service</h4>
                            <div class="p-4 bg-white rounded mb-4">
                                <div class="row g-4">
                                    <div class="col-3">
                                        <img src="/assets/img/author-img.webp" class="img-fluid  w-100" alt="" />
                                    </div>
                                    <div class="col-9">
                                        <div class="d-flex justify-content-between">
                                            <h5>Mary</h5>
                                            
                                        </div>
                                        
                                        <p class="mb-0">I am Mary, a professional technician who works to resolve technical queries related to common issues. I believe a perfect piece of information can tackle, provide, and protect you from futuristic grudges. With experience of more than 12 years, I am able to pinpoint where to work to resolve the error.
                                        </p>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                   
                </div>
            </div>
        </div>
       


</>

  )
}

export default Blog