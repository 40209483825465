import React from 'react';
import { Link } from 'react-router-dom';
// import  { useContext } from 'react';

function Header() {
  return (

<div>   
        <div class="container-fluid sticky-top px-0">
           
            <div class="container-fluid header-main">
                <div class="container px-0">
                    <nav class="navbar navbar-light navbar-expand-xl">
                        <Link to="/" class="navbar-brand ">
                           <img src="/assets/img/logo.png" class="img-responsive" alt="logo" />
                        </Link>
                        <button class="navbar-toggler py-1 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" >
                            <span class="fa fa-bars text-primary"></span>
                        </button>
                        <div class="collapse navbar-collapse py-2" id="navbarCollapse">
                            <div class="navbar-nav mx-auto border-top">
                            <Link to="/" class="nav-item nav-link">Home</Link>
                                <Link to="/about/" class="nav-item nav-link">About Us</Link>
                                
                                <div class="nav-item dropdown">
                                    <Link to="/service" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Services</Link>
                                    <div class="dropdown-menu m-0 bg-secondary rounded-0">                                        
                                        <Link to="/customer-service/amazon/" class="dropdown-item">Amazon Customer Service</Link>
                                        <Link to="/customer-service/cash-app/" class="dropdown-item">Cash App Customer Service</Link>
                                        <Link to="/customer-service/epson/" class="dropdown-item">Epson Suppor </Link>
                                        <Link to="/customer-service/ebay/" class="dropdown-item">eBay Customer Service</Link>
                                        <Link to="/customer-service/facebook/" class="dropdown-item">Facebook Customer Service</Link>
                                        <Link to="/customer-service/gmail/" class="dropdown-item">Gmail Customer Service</Link>
                                        <Link to="/customer-service/hotmail/" class="dropdown-item">Hotmail Customer Service</Link>
                                        <Link to="/customer-service/hp/" class="dropdown-item">Hp Customer Service</Link>
                                        <Link to="/customer-service/instagram/" class="dropdown-item">Instagram Customer Service</Link>
                                        <Link to="/customer-service/venmo/" class="dropdown-item">Venmo Customer Service</Link>
                                    </div>
                                </div>
                                <Link to="/blogs/" class="nav-item nav-link">Blog</Link>
                                <Link to="/contact/" class="nav-item nav-link">Contact Us</Link>
                            </div>
                            {/* <div class="d-flex flex-nowrap border-top pt-3 pt-xl-0">
                               
                                <button class="btn-search btn  btn-md-square   my-auto" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fas fa-search text-dark"></i></button>
                            </div> */}
                            <div class="d-flex flex-nowrap border-top pt-3 pt-xl-0">
                               
                               <button class="btn-search btn  btn-md-square   my-auto" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fas fa-search text-dark"></i></button>
                           </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
       



 {/* Modal Search Start */}
 <div class="modal fade" id="searchModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content rounded-0">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body d-flex align-items-center">
                        <div class="input-group w-75 mx-auto d-flex">
                            <input type="search" class="form-control p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                            <span id="search-icon-1" class="input-group-text p-3"><i class="fa fa-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Modal Search End */}

        </div>




  )
}

export default Header