import React from 'react';
import { Helmet } from 'react-helmet';

function Privacypolicy() {
  return (
   <>
 <Helmet>
        <title>Privacy Policy | Important</title>
        <meta name='description' content="We are compelled to guarantee the user’s information. Always go through Privacy Policy to use the website utmost." />
      </Helmet>

<section className="breadcurmb_sec">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="breadcurmb_title">
            <ul>
              <li>
                <a href="/home">Home</a>Privacy Policy
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div className="container-fluid  py-4 px-4">
    <div className="row g-4">
      <div className="container">
        <div className="col-md-12 p-4 shadow">
          <div className="section-title text-center">
            <h1>Privacy Policy</h1>
          </div>
          <div className="content-box">
                <p>The privacy and personal information of the user are very important for us. To make our website accessible and well organized we gathered information of the user. The personal information of the user may be collected by cache, cookies and by tracking user's browser history. We always recommend our users to go through our privacy policy carefully as through this user will know how we have collected and utilized the data. Please be ensured we never pass your data and information to another website. Apart from this, we assure you that we always keep you your data protected and nobody can invade your account without proper permission. To run the website securely and to give the user with better administration we gather the data.</p>

                <h2>Information that we collected</h2>
                <p>The user is required to write the details as mentioned below. We might collect few personal information to report spam, participate in surveys, polls and many more.</p>

                <ul>
                <li>Mobile Number:</li>
                <li>Date Of Birth:</li>
                <li>Address</li>
                <li>User name:</li>
                <li>Qualification</li>
                <li>Profile Picture</li>
                <li>Pincode</li>
                <li>Country</li>
                <li>Cookies & Cache</li>
                <li>Clear Gifs</li>
                <li>Logfile Information (IP Address, browsing data)</li>
                </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
   </>
  )
}

export default Privacypolicy