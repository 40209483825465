import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './header'
import Footer from './footer'

function layout() {
  return (
    <div>
    <Header/>
        <Outlet/>
<Footer/>
    </div>
  )
}

export default layout