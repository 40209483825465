import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
    const [data, setData] = useState(null);
  
    useEffect(() => {
      // Define the URL of your PHP API
      const apiUrl = 'https://www.contact-customerservice.co/api.php';
  
      // Make a GET request to your PHP API
      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          // Set the retrieved data to state
          setData(data);
        })
        .catch(error => {
          // Handle any errors
          console.error('Error fetching data:', error);
        });
    }, []); // Empty dependency array ensures the effect runs only once
  return (
   <>
    <Helmet>
        <title>All-in-One Solution At One Place | Support</title>
        <meta name='description' content="Feel free to contact our support team for any query as the team of technicians is always there to assist you on time. Visit our website for a quick solution." />
      </Helmet>
   <div class="container-fluid populer-news py-4 px-4">            
                <div class="row g-4">
                {data && (
                    <div class="col-md-3">
                         {data?.slice(0,3)?.map(item => (
                            <div>
                                 {item?.cat_id?.includes('Cash App') ? (
                        <div class="whats-item home-side">
                            <div class=" rounded">
                                <div class="rounded-top overflow-hidden">
                                    <img src={item.photo} class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div class="d-flex flex-column py-3">
                                    <Link to={`blog/${item.url}/`} class="post-title">{item.title}</Link>
                                    <div class="d-flex justify-content-between">
                                        <a href="#" class="small text-body link-hover author">by Mary</a>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                         
                         ): (
                            <></>
                          )}
                        </div>
                     ))}
                    </div>
                )}
                  {data && (
                    <div class="col-md-6">
                         {data?.slice(0,3)?.map(item => (
                        <div>
                        {item?.cat_id?.includes('HP Printer') ? (
                        <div class="whats-item">
                            <div class=" rounded">
                                <div class="rounded-top overflow-hidden">
                                    <img src={item.photo} class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div class="d-flex flex-column py-3">
                                    <Link to={`blog/${item.url}/`} class="post-title">{item.title}</Link>
                                    <p class="mb-0 pt-1">Let's look at the OnePlus 12 through the lens of a person who loved the OnePlus One.</p>
                                    <div class="d-flex justify-content-between">
                                        <a href="#" class="small text-body link-hover author">by Mary</a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                          ): (
                            <></>
                          )}
                        </div>
                         ))}
                    </div>
                    )}
                     {data && (
                    <div class="col-md-3">
{data?.slice(0,2)?.map(item => (
<div>


                        <div class="whats-item home-side">
                            <div class=" rounded">
                                <div class="rounded-top overflow-hidden">
                                    <img src={item.photo} class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                </div>
                                <div class="d-flex flex-column py-3">
                                    <Link to={`blog/${item.url}/`} class="post-title">{item.title}</Link>
                                    <div class="d-flex justify-content-between">
                                       
                                        <a href="#" class="small text-body link-hover author">by Mary</a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
 ))}
                    </div>
 )}
                </div>            
        </div>    


        <div class="container-fluid py-4 px-4 latest">
            
                <div class="row g-4">
                
                    <div class="col-lg-6 col-xl-6 mt-0">
                    
                    <div class="blog_heading">
                   <h3>News <i class="bi bi-arrow-right"></i></h3>
                 </div>
                        
                    {data && (
                            <div class="row g-4">
                           
                               {data?.slice(0,11)?.map(item => (
                                <div>
                                     {item?.cat_id?.includes('Cash App') ? (
                                    <div>
                                <div class="news-list">
                                    <div class="row">
                                        <div class="col-md-9">
                                            <div class="title">
                                                <Link to={`blog/${item.url}/`}class="h3">{item.title}</Link>
                                            </div>
                                            <div class="d-flex auth-info">
                                                <p class="mb-0 fw-bold pe-2">Mary </p>
                                                <p class="mb-0  "> {item.posted_date}</p>
                                            </div>
                    
                                        </div>
                                        <div class="col-md-3">
                                            <div class=" overflow-hidden">
                                                <img src={item.photo} class="img-fluid  img-zoomin w-100" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                     ): (
                                        <></>
                                      )}
                                </div>
                                ))}
                    
                            </div>
                            
                            )}
                    </div>

                    <div class="col-lg-6 col-xl-6 mt-0"  >
                    <div class="blog_heading">
                   <h3>Features <i class="bi bi-arrow-right"></i></h3>
                 </div>
                        


                        {data && (
                            <div class="row g-4 py-4  pt-4">
                               {data.map(item => (
                                <>
                                    {item?.cat_id?.includes('Facebook') ? (
                                <div class="col-12">
                                    <div class="row g-4 align-items-start px-3 px-3">
                                        <div class="col-7">
                                            <div class="features-content d-flex flex-column">
                                                <Link to={`blog/${item.url}/`} class="h3">{item.title}</Link>
                                               
                                            </div>
                                            <div class="d-flex auth-info">
                                                <p class="mb-0 fw-bold pe-2">Mary </p>
                                                <p class="mb-0  "> {item.posted_date}</p>
                                            </div>
                                        </div>
                                        <div class="col-5">
                                            <div class="overflow-hidden latest-homeright ">
                                                <img src={item.photo} class="img-zoomin img-fluid  w-100" alt="" />
                                            </div>
                                        </div>
                                      
                                    </div>
                                </div>
                                 ): (
                                    <></>
                                  )}
                               </>
                                
                                ))}
                               
                            </div>
                             )}
                       
                    </div>
                </div>
            
        </div>






        <div class="container-fluid py-4 px-4 latest pt-5">
            
            <div class="row g-4">
                <div class="col-lg-6 col-xl-6 mt-0"  >
                <div class="blog_heading">
                   <h3>Ebay <i class="bi bi-arrow-right"></i></h3>
                 </div>


                    {data && (
                        <div class="row g-4 py-4  pt-4">
                              {data.map(item => (
                                <>
                                    {item?.cat_id?.includes('eBay') ? (
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3 px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <Link to={`blog/${item.url}/`} class="h3">{item.title}</Link>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> {item.posted_date}</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src={item.photo} class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            ): (
                                <></>
                              )}
                           </>
                            
                            ))}
                           
                           
                            <div class="row">
                                <div class=" mx-3 more-btn text-center mt-4">
                                    <a href="#" class="d-inline py-3">Load More</a>
                                </div>
                            </div>
                        </div>
                         )}
                   
                </div>

                <div class="col-lg-6 col-xl-6 mt-0"  >
                <div class="blog_heading">
                   <h3>The best <i class="bi bi-arrow-right"></i></h3>
                 </div>
                    


                   
                    {data && (
                        <div class="row g-4 py-4  pt-4">
                              {data?.slice(0,3)?.map(item => (
                                <>
                                    
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3 px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <Link to={`blog/${item.url}/`} class="h3">{item.title}</Link>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> {item.posted_date}</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src={item.photo} class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                           
                           </>
                            
                            ))}
                           
                           
                            <div class="row">
                                <div class=" mx-3 more-btn text-center mt-4">
                                    <a href="#" class="d-inline py-3">Load More</a>
                                </div>
                            </div>
                        </div>
                         )}
                        
                   
                </div>
            </div>
        
    </div>


       {/*
        <div class="container-fluid populer-news py-4 px-4">
            
                <div class="tab-class mb-4">
                    <div class="row g-4">
                        <div class="col-lg-6 col-xl-6">
                            
                            <div class="border-bottom mb-4">
                                <h2 class="my-4">Most Views News</h2>
                            </div>
                            <div class="whats-carousel owl-carousel">
                                <div class="latest-news-item ">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-7.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">What is a Chromebook and how does it compare to a laptop?</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-6.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">What is a Chromebook and how does it compare to a laptop?</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">The best Chromebook tablets you can buy</a>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-4.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">You told us: Galaxy Ring or Oura Ring? It's not even close</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-5.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">There are many variations of passages of Lorem Ipsum available,</a>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        <div class="col-lg-6 col-xl-6">
                            
                            <div class="border-bottom mb-4">
                                <h2 class="my-4">Most Views News</h2>
                            </div>
                            <div class="whats-carousel owl-carousel">
                                <div class="latest-news-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-7.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">What is a Chromebook and how does it compare to a laptop?</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-6.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">What is a Chromebook and how does it compare to a laptop?</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">The best Chromebook tablets you can buy</a>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-4.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">You told us: Galaxy Ring or Oura Ring? It's not even close</a>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div class="whats-item">
                                    <div class="">
                                        <div class="rounded-top overflow-hidden">
                                            <img src="/assets/img/news-5.jpg" class="img-zoomin img-fluid rounded-top w-100" alt="" />
                                        </div>
                                        <div class="d-flex flex-column py-4">
                                            <a href="#" class="h4">There are many variations of passages of Lorem Ipsum available,</a>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                </div>
            
        </div> */}
        

{/*
        <div class="container-fluid py-4 px-4 latest">
            
            <div class="row g-4">
                <div class="col-lg-6 col-xl-6 mt-0"  >
                <div class="blog_heading">
                   <h3>Audio</h3>
                 </div>
                    <div class="position-relative overflow-hidden  feature">
                        <img src="https://www.androidauthority.com/wp-content/uploads/2024/02/Android-15-logo-on-smartphone-on-coffee-table-stock-photo-6-675w-380h.jpg.webp" class="img-fluid  img-zoomin w-100" alt="" />
                        <div class="d-flex justify-content-start px-4 position-absolute flex-wrap feater_img_on_txt" >
                            <a href="#" class="h3 text-white">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                            <a href="#" class="text-white me-3 fw-bold link-hover"> Andrew Grush</a>
                            <a href="#" class="text-white me-3  link-hover"> March 11, 2024</a>
                            
                        </div>
                        
                    </div>


                   
                        <div class="row g-4 py-4  pt-4">
                           
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3 px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class=" mx-3 more-btn text-center mt-4">
                                    <a href="#" class="d-inline py-3">Load More</a>
                                </div>
                            </div>
                        </div>
                        
                   
                </div>

                <div class="col-lg-6 col-xl-6 mt-0">
                <div class="blog_heading">
                   <h3>Streaming</h3>
                 </div>
                    <div class="position-relative overflow-hidden  feature">
                        <img src="https://www.androidauthority.com/wp-content/uploads/2024/02/Android-15-logo-on-smartphone-on-coffee-table-stock-photo-6-675w-380h.jpg.webp" class="img-fluid  img-zoomin w-100" alt="" />
                        <div class="d-flex justify-content-start px-4 position-absolute flex-wrap feater_img_on_txt" >
                            <a href="#" class="h3 text-white">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                            <a href="#" class="text-white me-3 fw-bold link-hover"> Andrew Grush</a>
                            <a href="#" class="text-white me-3  link-hover"> March 11, 2024</a>
                            
                        </div>
                        
                    </div>


                   
                        <div class="row g-4 py-4  pt-4">
                           
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3 px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" /> 
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden latest-homeright ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" /> 
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row g-4 align-items-start px-3">
                                    <div class="col-7">
                                        <div class="features-content d-flex flex-column">
                                            <a href="#" class="h3">iPadOS 18 wishlist: What I want to see and what we know so far</a>
                                           
                                        </div>
                                        <div class="d-flex auth-info">
                                            <p class="mb-0 fw-bold pe-2">Mary </p>
                                            <p class="mb-0  "> 13 hours ago</p>
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="overflow-hidden ">
                                            <img src="/assets/img/news-3.jpg" class="img-zoomin img-fluid  w-100" alt="" />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class=" mx-3 more-btn text-center mt-4">
                                    <a href="#" class="d-inline py-3">Load More</a>
                                </div>
                            </div>
                        </div>
                        
                   
                </div>
            </div>
        
    </div>*/}


   </>
  )
}

export default Home