import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <>
    <Helmet>
        <title>Contact Us | Enquiry | Query| Feedback</title>
        <meta name='description' content="To enquire about anything related to this website please free to Contact Us. The team of professionals is always ready to assist you." />
      </Helmet>
      <section class="breadcurmb_sec">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="breadcurmb_title">
                <ul>
                  <li><Link to="/">Home  </Link>About</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact_sec_box">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="contact_content_box">
                <p>
                 
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="content">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div class="row align-items-center">
                  <div class="col-lg-7 mb-5 mb-lg-0">
                    <h2 class="mb-5">Contact Us</h2>
                    <form class="border-right pr-5 mb-5" method="post" action="#" id="contactForm" name="contactForm">
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <input type="text" class="form-control" name="fname" id="fname" placeholder="First name" />
                        </div>
                        <div class="col-md-6 form-group">
                          <input type="text" class="form-control" name="lname" id="lname" placeholder="Last name" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <input type="text" class="form-control" name="email" id="email" placeholder="Email" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 form-group">
                          <textarea class="form-control" name="message" id="message" cols="30" rows="7" placeholder="Write your message"></textarea>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          
                            <button class="btn btn-primary rounded-0 py-2 px-4 my-3">Submit</button>
                        </div>
                      </div>
                     
                    </form>
                    {/* <div id="form-message-warning mt-4"></div>
                    <div id="form-message-success">
                      Your message was sent, thank you!
                    </div> */}
                  </div>
                  <div class="col-lg-4 ml-auto contact_prgh">
                   
                    <p>If you are accessing this educational blog and having issues related to its content then feel free get in touch with us. You need to write the details and as asked inbox and then press the "Send Message". You will get peculiar assistance from our team regarding every question. Apart from this, feedback from you is always welcome as it encourages our involvement towards work and always try to fill deceptions if there any.</p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact