import React from 'react'
import { Helmet } from 'react-helmet'

function Disclaimer() {
  return (
    <>
     <Helmet>
        <title>Disclaimer | Declaration</title>
        <meta name='description' content="Disclaimer is a declaration that is used to restrict or restrain liberty.  Before proceeding please go through the website." />
      </Helmet>

        <section className="breadcurmb_sec">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="breadcurmb_title">
            <ul>
              <li>
                <a href="/home">Home &gt; </a>Disclaimer
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>


  <div className="container-fluid  py-4 px-4">
    <div className="row g-4">
      <div className="container">
        <div className="col-md-12 p-4 shadow">
          <div className="section-title text-center">
            <h1>Disclaimer</h1>
          </div>
          <div className="content-box">
                <p>The use of trademarks, images, logos and brand names is intended for information are the referral 
                     purposes only. The information provided on the website is for knowledge sharing only, although 
                     we have done our research on each and every material provided on the website, users should use 
                     their concerns before using the information. We do not guarantee the website of any kind.
                      Our website may contain inaccuracies or typo-errors for which we cannot be held responsible for 
                      such mistakes. All information shared on the website is provided for educational purpose; 
                      However, we do not claim the completeness, accuracy, authenticity of the information. 
                      We disclaim our responsibility for any damages caused to relationships after using our websites.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

    </>
  )
}

export default Disclaimer