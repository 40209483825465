import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
function About() {
  return (
   <>
   <Helmet>
        <title>All-in-One Solution At One Place | Support</title>
        <meta name='description' content="Feel free to contact our support team for any query as the team of technicians is always there to assist you on time. Visit our website for a quick solution." />
      </Helmet>
   <section class="breadcurmb_sec">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="breadcurmb_title">
            <ul>
              <li><Link to="/home">Home  </Link>About</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </section>

    {/* =====start-about_sec==== */}
    <section class="about_content_sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-lg-6 col-12 d-flex align-items-center">
            <div class="about_content_box">
              <p>
              In today's fully competitive world, individuals or businesses are totally dependent on several IT
               platforms, especially internet services and products. Also, people who are new to the technology
                world also showing their interests in technology. Such interest in IT and telecommunication is obvious,
                 as it brings with itself a lot of opportunities for businesses. A newbie of any technology has to face
                  some sort of technical confusion. In order to provide you better
               guidance and an good know-how of technology, we are here to provide backup support.
              </p>
              <p>
              In short, we are a one-stop solution provider of technical assistance of different brands and platforms.
               If you have any issue with your mailing account- Not able to perform some activities as per your choice,
                then you can take assistance from our experts. If your printer doesn't work, and you want quick and 
                effective guidance on it, then you can easily connect with our staff for a genuine solution.
                 Furthermore, if you want the online presence of your business through a specific social media platform either Facebook,
                  Instagram, or others, or getting some login, 
              account hack or other troubles, then we have a sure shot solution for all.
              </p>
            </div>
          </div>

          <div class="col-lg-6 col-lg-6 col-12">
            <div class="about_image_box">
              <img src="/assets/img/customer-service.jpg" class="d-block img-fluid" alt="about image" />
            </div>
          </div>
        </div>
        <div class="row py-5">
          <div class="col-12">
            <div class="about_list_box">
              <h4>What We Do To Make You Confident In Technology?</h4>
              <ul>
                <li>We update technology content ( mailing, social media, internet services, printer, and ecommerce services), based on the latest news, technical errors, and some valuable tricks.</li>
                <li>Remain active round the clock with our customer care executives to give you assistance on any of the topics.</li>
                <li>We discuss your concerns in different modes of communication either live chat, mail or phone.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
   </>
  )
}

export default About