import React from 'react'

function NotFound() {
  return (
    <>
    <main id="main" data-aos="fade-up">




 
<section class="inner-page error">
    <div class="container">
        <div class="row">
          <div class="" >

            
            <img    src="/assets/img/page-found-concept-illustration_114360-1869.jpg" alt="404 error Page" class="img-fluid " /> 
            <div class="error-text">
              <h1>404</h1>
            <p>Oops! Page not found.</p>
            </div>
          </div>    
        </div>
    </div>
</section>

</main>





    </>
    
  )
}

export default NotFound