import logo from './logo.svg';
import './App.css';
import Header from './components/header';
import Layout from './components/layout';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Testing from './pages/testing';
import Blog from './pages/blog';
import Epson from './services/epson';
import Facebook from './services/facebook';
import Venmo from './services/venmo';
import Instagram from './services/instagram';
import Hp from './services/hp';
import Disclaimer from './pages/disclaimer';
import Privacypolicy from './pages/privacypolicy';
import Hotmail from './services/hotmail';
import Gmail from './services/gmail';
import Cashapp from './services/cashapp';
import Amazon from './services/amazon';
import NotFound from './pages/404';

import ReactGA from 'react-ga';

import { BrowserRouter as Router , Routes, Route, Switch } from 'react-router-dom';
import Ebay from './services/ebay';
import React, { Suspense } from 'react';
const Blogs = React.lazy(() => import('./pages/blogs'));

function App() {
  ReactGA.initialize('UA-164527047-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <>
   
           <Router>
           <Suspense fallback={<div>Loading...</div>}>
              <Routes>
              
                  <Route  path="/" element={<Layout />}  >
                  <Route  index element={<Home />}  />
                  <Route path="/about" element={<About />}  />
                  <Route path="/contact" element={<Contact />}  />
                  <Route path="/blog/:data" element={<Blog />}  />
                  <Route path="/blogs" element={<Blogs />}  />
                  <Route path="/customer-service/epson" element={<Epson />}  />
                  <Route path="/customer-service/facebook" element={<Facebook />}  />
                  <Route path="/customer-service/amazon" element={<Amazon />}  />
                  <Route path="/customer-service/cash-app" element={<Cashapp />}  />
                  <Route path="/customer-service/ebay" element={<Ebay />}  />
                  <Route path="/customer-service/gmail" element={<Gmail />}  />
                  <Route path="/customer-service/hotmail" element={<Hotmail />}  />
                  <Route path="/privacy-policy" element={<Privacypolicy />}  />
                  <Route path="/disclaimer" element={<Disclaimer />}  />
                  <Route path="/customer-service/hp" element={<Hp />}  />
                  <Route path="/customer-service/instagram" element={<Instagram />}  />
                  <Route path="/customer-service/venmo" element={<Venmo />}  />
                  <Route path='*' element={<NotFound/>} />
                  </Route>
                  {/* <Route path="/testing1" element={<Testing />}  /> */}
              </Routes>
              </Suspense>
           </Router>
          
    </>
  );
}

export default App;
